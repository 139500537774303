if (
  typeof window.Sentry !== 'undefined' &&
  'application_id' in window.theme &&
  window.theme.application_id !== null &&
  window.theme.application_id !== 'undefined'
) {
  window.Sentry.setTag('application_id', window.theme.application_id);
}

window.captureError = function captureError(...args) {
  if (typeof window.Sentry !== 'undefined') {
    window.Sentry.captureException(...args);
  } else {
    console.error(...args);
  }
};
